@mixin Circle {
    --size: 40px; width: var(--size); height: var(--size); border-radius: var(--size); display: flex; align-items: center; justify-content: center; overflow: hidden; cursor: pointer;    
}
.icon-rounded {@include Circle}

@mixin horizontalList {
    display: flex; align-items: center; grid-gap: 30px;
}
.h-list {@include horizontalList}

// ==========global===========
* {box-sizing: border-box;}

body {
    font-family: var(--primary-font); font-size: var(--content-size); padding: 0; margin: 0;
}

a {color: inherit; text-decoration: none; transition: all .3s ease;}
a:hover,
a:focus {color: var(--link-hover) !important;}

img {max-width: 100%;}
ul {list-style: none; padding: 0; margin: 0;}

h1, .h1 {font-size: 48px; font-weight: 700;}
h2, .h2 {font-size: 34px; font-weight: 700;}
h3, .h3 {font-size: 30px; font-weight: 700;}
h4, .h4 {font-size: 24px; font-weight: 700;}
h5, .h5 {font-size: 18px; font-weight: 700;}
h6, .h6 {font-size: 14px; font-weight: 400;}

.text-grey {color: #676767;}
.text-center {text-align: center; width: 100%;}
.text-right {text-align: right; width: 100%;}
.font-size-14 {font-size: 14px !important;}
.font-size-16 {font-size: 16px !important;}
.font-size-18 {font-size: 18px !important;}
.font-size-20 {font-size: 20px !important;}
.font-size-22 {font-size: 22px !important;}
.font-weight-400 {font-weight: 400 !important;}
.font-weight-500 {font-weight: 500 !important;}
.font-weight-600 {font-weight: 600 !important;}
.font-weight-700 {font-weight: 700 !important;}
.rotate-90 {transform: rotate(90deg);}
.rotate-180 {transform: rotate(180deg);}

.svg-icon {display: block; margin: auto; width: 18px;}

.flex {display: flex;}
.grid {display: grid;}
.fr-auto {display: grid; grid-template-columns: 1fr auto;}

.btn {font-size: 14px; font-weight: 600; min-height: 48px; border: 0; display: flex; align-items: center; justify-content: center;}
.btn-rounded {border-radius: 25px; min-width: 160px;}
.btn-secondary {background-color: var(--primary);}
.btn-secondary:hover {background: var(--link-color); color: #fff !important;}
 
.btn-link {font-size: 18px; color: var(--secondary); padding: 0; background: transparent; border: 0; outline: none !important; box-shadow: none !important; text-decoration: none;}
.link {font-size: 14px; color: var(--link-color); font-weight: 500; display: inline-block;}

.container {max-width: 1340px; margin: auto;}

// form
form .checkbox {margin-right: 10px;}
.form-group {margin-bottom: 15px; position: relative;}
.form-group label {display: block; width: 100%; font-size: 16px; font-weight: 600; color: var(--content-color); margin: 0 0 7px;}
.form-group .form-icon{
    position:absolute;
    top: 50%;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}
.PhoneInputInput, .form-control {background: #fff; border: 1px solid rgba(213, 215, 218, 1); box-shadow: none !important; padding: 0px 16px; outline: none; height: 46px; width: 100%; font-size: 15px; line-height: 24px; border-radius: 4px;}
.wrap-icon {
    position: relative;
    .form-control {padding-left: 50px;}
    .icon {display: flex; align-items: center; justify-content: center; color: #000; position: absolute; left: 15px; top: 50%; transform: translateY(-50%); z-index: 1;}
    .icon svg {width: 20px;}
}
.PhoneInputInput {
    border-radius: 0 4px 4px 0;
}
.PhoneInputCountry {
    background: #f3f3f3;
    border: 1px solid #d5d7da;
    width: 50px;
    padding: 8px;
    font-size: 18px;
    margin: 0 -1px 0 0;
    border-radius: 4px 0 0 4px;
}
.country-select {
    display: flex;
    select {
        width: 80px;
        border-color: #ccc;
        margin-right: -1px;
        font-size: 14px;
        border-radius: 4px 0 0 4px;
        padding: 0 10px;
    }
}

.switch { 
    position: relative; display: inline-block; width: 44px; height: 22px;
    input { opacity: 0;width: 0;height: 0;}
    .slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ccc; -webkit-transition: .4s; transition: .4s;}
    .slider:before { position: absolute; content: ""; height: 16px; width: 16px; left: 4px; bottom: 3px;background-color: white;-webkit-transition: .4s;transition: .4s;}  
    input:checked + .slider {background-color: #2196F3;}  
    input:focus + .slider {box-shadow: 0 0 1px #2196F3;}
    input:checked + .slider:before {-webkit-transform: translateX(20px); -ms-transform: translateX(20px); transform: translateX(20px);}  
    .slider.round {border-radius: 34px;}
    .slider.round:before {border-radius: 50%;}
}  

.label {background: #fff; color: #000; line-height: 24px; text-transform: uppercase; align-items: center; display: inline-flex; justify-content: center; font-size: 11px; font-weight: 700; line-height: 18px; border-radius: 4px; height: 24px; padding: 3px 8px}
.popular-label {background-color: #cde4ff; color: #037aff;}

section {padding: 60px 0;} 
.section-title {margin-bottom: 40px;}
.not-found {
    font-weight: normal;
    height: 400px;
    box-shadow: 0 2px 20px #eee;
    font-size: 18px;
    line-height: 1.8;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    img {
        width: 100px;
        height: auto;
        margin-bottom: 20px;
        display: block;
    }
    @media screen and (max-width: 767px) {
        font-size: 16px;
        padding: 0 20px;
        br {
            display: none;
        }
    }
}

.select-picker {
    .css-qc6sy-singleValue,
    .css-14el2xx-placeholder {padding: 5px 15px; color: var(--link-color);}
    .css-6j8wv5-Input input {display: none !important;}
    .css-6j8wv5-Input {min-width: 100px; padding: 5px 15px;}
    .css-1s2u09g-control {border-radius: 25px; min-height: 36px; min-width: 140px; }
    .css-1okebmr-indicatorSeparator {display: none;}
    .css-tlfecz-indicatorContainer {margin-right: 5px; color: var(--link-color);}
    .css-26l3qy-menu {z-index: 8;}
    @media screen and (max-width: 812px){
        .css-1s2u09g-control,
        .css-6j8wv5-Input {min-width: 70px;}
    }
}

.slick-arrow {
    position: absolute; left: 15px; top: 50%; transform: translateY(-50%); z-index: 9; background: transparent; font-size: 0; border: 0;
    border: solid #fff;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    width: 10px; height: 10px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    &.slick-next {left: auto; right: 15px; transform: rotate(-45deg); -webkit-transform: rotate(-45deg);}
    &::before {display: none;}

    &.slick-disabled {opacity: .5; pointer-events: none;}
}

.slick-dots {
    position: absolute; left: 50%; transform: translateX(-50%); bottom: 15px;
    li {margin: 0;}
    li button:before {font-size: 10px; color: #fff !important;}
}

.suggestions {
    display: flex; flex-wrap: wrap; grid-gap: 8px;
    .label {text-transform: capitalize; border-radius: 25px; border: 1px solid #ccc; color: var(--secondary); font-size: 12px; padding: 6px 12px; height: auto;}
}

.search-wrapper {
    display: flex; align-items: center; grid-gap: 10px;
    .form-control {min-height: 40px; border: 1px solid rgba(213, 215, 218, 1) !important; border-radius: 4px; height: auto; box-shadow: rgba(16, 25, 40, 0.06) 0px 4px 6px 0px !important;}

    .search-services {position: relative; width: 400px; transition: all .3s ease;}
    .search-services .form-control {padding-left: 50px;}
    .search-dropdown {
        outline: none !important; max-height: calc(100vh - 99vh); overflow-y: auto; visibility: hidden; transition: all 500ms ease 0s, opacity 500ms ease 0s; position: absolute; left: 0; top: 40px; margin-top: -2px; z-index: 5; border: 1px solid rgba(213, 215, 218, 1) !important; box-shadow: rgba(16, 25, 40, 0.06) 0px 4px 6px 0px; border-radius: 0 0 4px 4px; background: #fff; 
        .suggestions {padding: 15px;}
    }

    .loc-wrapper {
        position: relative; width: 0px; visibility: hidden; transition: all .3s ease; right: 0; left: auto; opacity: 0;
    }

    .active {
        .search-dropdown {visibility: visible; max-height: calc(100vh - 80px);}
    }

    &.active {
        width: 600px;
        .search-services {width: 295px;}
        .loc-wrapper {
            position: relative; width: 295px; visibility: visible; opacity: 1;
        }
        h6, button {display: none;}
    }
}

.sticky-header {
    .site-header {
        position: sticky;
       top: 0;
    }
    .logo,
    .profile-dropdown {
        position: relative;
        z-index: 99;
    }
    .fixed-salon-section {
        top: 78px !important;
        opacity: 1 !important;
        visibility: visible !important;
    }
}

// responsive
.show-on-mobile {display: none !important; visibility: hidden;}

@media screen and (max-width: 1500px) {
    body {font-size: 14px;}
    h1, .h1 {font-size: 40px;}
    h2, .h2 {font-size: 28px;}
    h3, .h3 {font-size: 24px;}
    h4, .h4 {font-size: 20px;}
    h5, .h5 {font-size: 16px;}
}

@media screen and (max-width: 991px) {
    section {padding: 40px 0;} 
    .section-title {margin-bottom: 30px;}

    .show-on-mobile {display: inherit !important; visibility: visible;}
    .hide-on-mobile {display: none !important; visibility: hidden;}
}