
.Toastify__toast-container {
    z-index: 999999 !important;
}
.home-banner {
    position: relative; background-size: cover; background-repeat: no-repeat; display: flex; align-items: center; justify-content: center; padding: 60px 0 90px;
    &::before {content: ''; padding-top: 34%; display: block;}

    .caption-box {
        background: #fff; border-radius: 7px; padding: 35px 40px; box-shadow: rgba(40, 34, 16, .15) 0px 24px 64px 0px; min-width: 500px; max-width: 500px;
        h2 {line-height: 1.2; margin-bottom: 30px;}

        .btn {font-size: 18px; font-weight: 500; min-height: 60px;}
        .btn .icon {width: 20px; margin-right: 10px;}
    }

    @media screen and (max-width: 1500px){
        .caption-box {
            max-width: 400px;
            h2 {font-size: 30px;}
        }

    }

    @media screen and (max-width: 767px){
        background-size: contain;
        background-position: -80% 100%;
        background-color: #eaeff8;
        .caption-box {
            min-width: 250px; max-width: 250px; box-shadow: none; background: transparent; padding: 0;
            h2 {font-size: 21px; line-height: 1.4; padding-left: 15px; margin: 0; max-width: 225px;}

            .btn {position: absolute; bottom: -20px; left: 2%; width: 96%; font-size: 14px; min-height: 48px;}
        }

    }
    @media screen and (max-width: 450px){
        padding: 40px 0 110px;
        background-position: -80% 100%;
        background-color: #eaeff8;
        .caption-box {
            min-width: 250px; max-width: 250px; box-shadow: none; background: transparent; padding: 0;
            h2 {font-size: 16px; line-height: 1.4; padding-left: 0px; margin: 0; max-width: 165px; margin-top: -35px;}

            .btn {position: absolute; bottom: -20px; left: 2%; width: 96%; font-size: 14px; min-height: 48px;}
        }

    }
    @media screen and (max-width: 380px){
        .caption-box {
            h2 {
                max-width: 170px;
            }
        }
    }

}

.featured-card {
    box-shadow: 0 2px 10px #ddd;
    .grid {
        padding: 10px;
        h5 {
            margin-bottom: 10px !important;
        }
    }
    @media screen and (max-width: 767px){
        margin: 0 10px;        
    }
}

// .featured-post-card{
//   width: 90%;
// }
.width-100{
    width: 100% !important;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
.post-card-outer{
    width: 90%;
    box-shadow: 0 2px 10px #ddd;
    border: solid 2px rgba(255, 255, 255, 0);
    min-height: 425px;
    background-color: #fff;
    cursor: pointer;
    border-radius: 7px;
    // padding: 10px;
    position: relative;
    transition: 350ms;
    overflow: clip;

    a.custom-button:visited{
        color: white !important;
        background-color: var(--primary) !important;
    }

    a:hover{
        color: #212529 !important;
    }

    &:hover{
        border: solid 2px var(--primary);
        box-shadow: 0 2px 10px #999;
        // transform: scale(1.03);
        color: #212529 !important;
    }

    .post-image{
        width: 100%;
        height: 200px;
        background-size: cover;
    }

    .text-small{
        font-size: 10px;
        font-weight: 600;
        margin-bottom: 5px;
        color: var(--link-color)
    }

    .post-status{
        padding: 5px 15px;
        font-weight: 500;
        font-size: 12px;
        background: var(--link-color);
        border-radius: 3px 0px 0px 3px;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 10px
    }

    .expired{
        background: var(--primary);
    }

    .time-text{
        font-size: 10px;
        margin-bottom: 0px;
    }

    .featured-card-title{
      min-height: 45px;
      font-size: 18px;
    }
    .featured-card-text{
      min-height: 45px;
    }
    @media screen and (max-width: 1024px) {
        width: 90% !important;
        border: 1px solid #ddd;
        box-shadow: none;
        &:hover {
            border: 1px solid #ddd;
            box-shadow: none;
        }
    }
}

.see-all-link{
    color: var(--link-color);
    padding: 5px 15px;
    background-color: #01358d25;
    border-radius: 5px;
}
.see-all-link:hover{
    background-color: #01358d35 ;
}

.custom-button{
    width: 100%;
    padding: 10px 15px;
    border: none;
    background-color: var(--primary);
    border-radius: 5px;
    color: white;
    font-size: 12px;
    font-weight: 500;
}

a.custom-button:hover{
    background-color: var(--link-color);
    color: white !important;
}

.featured-post-card{
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.slick-list{
  overflow: visible;
  overflow-x: clip;
}

.slick-arrow{
    padding: 5px;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50% !important;
  background-color: #999 !important;
  background-image: url("../../assets/icons/chevron.svg") !important;
  background-size: cover !important;
  background-position: center center !important;
  opacity: 0.75;
}

.slick-arrow.slick-prev{
  transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.slick-arrow.slick-next{
  transform: rotate(0deg) !important;
    -webkit-transform: rotate(0deg) !important;
}

.slick-arrow:hover{
    opacity: 1;

}

.slick-track {
    margin-left:0;
}


@media only screen and (max-width: 1150px) and (min-width: 995px)  {
    .search-wrapper{
        width: 375px;
    }
    .wrap-icon .icon svg {
        width: 15px !important;
    }
    .search-wrapper .search-services .form-control {
        padding-left: 35px !important;
    }
}