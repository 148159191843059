// varaiables
:root {
    --primary-font: 'Montserrat', sans-serif;
    --content-size: 16px;
    
    --primary: #f9556d;
    --link-hover: #f9556d;
    --secondary: #0d6efd; 
    --content-color: rgb(16, 25, 40);
    --link-color: #01358d;

}

// @imports
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';


.app {
    height: 100%;
}


// pages
@import './pages/home';
@import './pages/authpage';
@import './base/global';
@import './pages/search';
@import './pages/salons'