.salon-detail {
    padding: 40px 0;
    .top-header {
        background: #f3f3f3;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 50px;
        .text-holder {
            padding: 0 40px;
            h1 {
                font-size: 36px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;  
                overflow: hidden;
                margin-bottom: 15px;
            }
            .address {
                font-size: 16px;
                color: #666;
            }
            .ratings {
                margin-bottom: 35px;
                display: flex;
                align-items: center;
                span {
                    font-size: 16px;
                    color: #666;
                    display: inline-block;
                    margin-left: 10px;
                }
            }
            button {
                width: 100%;
                font-size: 18px;
                font-weight: 500;
                min-height: 60px;
            }
        }
        .image-slider {
            img {
                width: 100%;
            }
        }
        @media screen and (max-width: 992px) {
            .col-lg-4 {
                order: 2;
            }
            .text-holder {
                padding: 30px;
                h1 {
                    font-size: 30px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .fixed-salon-section {
        position: fixed;
        top: 70px;
        left: 0;
        right: 0;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        background: #fff;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        justify-content: space-between;
        z-index: 999;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s all ease;
        .ratings {
            display: flex;
            margin-left: 10px;
            .react-rater-star {
                color: #ffc107;
            }
            span {
                margin-left: 10px;
            }
        }
        & > div {
            display: flex;
            align-items: center;
        }
        span {
            font-weight: 500;
            color: #666;
        }
        button {
            padding: 10px 20px;
            margin-left: 20px;
            min-height: inherit;
        }
        @media screen and (max-width: 767px) {
            span {
                display: none;
            }
        }
    }
    .about-salon {
        .box-card {
            font-size: 16px;
            line-height: 1.8;
            h2 {
                margin-bottom: 20px;
            }
            .features {
                display: flex;
                margin-bottom: 20px;
                list-style: disc;
                padding-left: 15px;
                li {
                    & + li {
                        margin-left: 40px;
                    }
                }
            }
            .timings {
                li {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 8px;
                    &.highlight-day {
                        font-weight: bold;
                    }
                }
            }
            & + .box-card {
                margin-top: 80px;
            }
            &.location {
                button {
                    font-size: 14px;
                    border-bottom: 2px solid;
                    padding: 0;
                    border-radius: 0;
                    min-height: inherit;
                }
            }
        }
        .salon-services {
            margin-top: 50px;
            padding-top: 50px;
            border-top: 1px solid #ccc;
            .nav-link {
                color: #000;
                &:hover,
                &:focus {
                    color: #000 !important;
                }
            }
            .nav-pills .nav-link.active, 
            .nav-pills .show > .nav-link {
                background-color: #f8f8f8;
                color: #000;
                border-radius: 0 20px 20px 0;
                font-weight: bold;
            }
            .services-list {
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: -10px;
                    li {
                        width: 50%;
                        padding: 10px;
                        input[type="checkbox"]{
                            display: none;
                        }
                        input[type="checkbox"] + label {
                            position: relative;
                            border: 1px solid #ddd;
                            width: 100%;
                            padding: 35px 25px;
                            cursor: pointer;
                            pointer-events: none;
                            &:after {
                                content: "";
                                position: absolute;
                                top: 47px;
                                left: 30px;
                                width: 20px;
                                height: 10px;
                                border-left: 2px solid #fff;
                                border-bottom: 2px solid #fff;
                                transform: rotate(-45deg);
                                -webkit-transform: rotate(-45deg);
                                opacity: 0;
                                visibility: hidden;
                            }
                            strong {
                                display: block;
                                font-size: 16px;
                                line-height: 1.4;
                            }
                            .duration {
                                color: #666;
                                font-weight: 500;
                            }
                            .price-box {
                                position: absolute;
                                top: -1px;
                                right: -1px;
                                border: 1px solid #ddd;
                                padding: 7px 20px;
                                font-size: 14px;
                                line-height: 1.4;
                                font-weight: bold;
                                color: #000;
                                .ser-price {
                                    text-decoration: none;
                                }
                                .disc-price-other {
                                    font-size: 80%;
                                    text-decoration: line-through;
                                    color: #888;
                                    font-weight: normal;
                                }
                            }
                        }
                        input[type="checkbox"]:checked + label {
                            &:before {
                                background: var(--secondary);
                            }
                            &:after {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }
        .reviews {
            margin-top: 50px;
            padding-top: 50px;
            border-top: 1px solid #ccc;
            h2 {
                font-size: 22px;
            }
            .review-stars {
                margin-bottom: 20px;
                font-size: 14px;
                line-height: 1.4;
                span {
                    font-size: 16px;
                    font-weight: 500;
                    display: inline-block;
                    margin-left: 10px;
                }
                li {
                    font-size: 20px;
                    color: #ffc107;
                    & + li {
                        margin-left: 3px;
                    }
                }
            }
            .filter-by-ratings {
                margin-bottom: 30px;
                padding-right: 20px;
                strong {
                    font-size: 18px;
                    margin-bottom: 10px;
                    display: block;
                }
                .checkbox-holder {
                    width: 100%;
                    .custom-checkbox {
                        width: 100%;
                        padding-left: 30px;
                        position: relative;
                        input {
                            display: none;
                        }
                        label {
                            background: #fff;
                            border-radius: 6px;
                            width: 100%;
                            cursor: pointer;
                            &:before {
                                content: "";
                                position: absolute;
                                width: 20px;
                                height: 20px;
                                border-radius: 6px;
                                background: #fff;
                                border: 1px solid #ddd;
                                left: 0;
                                top: 3px;
                            }
                            &:after {
                                content: "";
                                position: absolute;
                                left: 5px;
                                top: 9px;
                                width: 10px;
                                height: 5px;
                                border: 2px solid var(--link-color);
                                border-left: none;
                                border-bottom: none;
                                transform: rotate(136deg);
                                -webkit-transform: rotate(136deg);
                                opacity: 0;
                                visibility: hidden;
                            }
                            .filled {
                                color: #ffc107;
                            }
                        }
                        input[type="checkbox"]:checked {
                            & + label {
                                &:after {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                        li {
                            font-size: 16px;
                            color: #222;
                            & + li {
                                margin-left: 2px;
                            }
                        }
                        .progress {
                            width: 100%;
                            margin: 0 15px;
                            border-radius: 20px;
                            height: 10px;
                            background: #efefef;
                            .progress-bar {
                                background-color: var(--link-color);
                            }
                        }
                        .review-numbers {
                            font-weight: 600;
                            padding: 0 10px;
                        }
                    }
                    & + .checkbox-holder {
                        margin-top: 5px;

                    }
                }
            }
            .reviews-trust {
                border-radius: 4px;
                padding: 25px;
                background: #eef4ff;
                margin-right: 20px;
                p {
                    margin: 0;
                }
                strong {
                    font-size: 16px;
                }
                svg {
                    font-size: 40px;
                    margin-right: 20px;
                    color: var(--link-color);;
                }
            }
            .review-lists {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                gap: 30px;
                .review-list {
                    flex: 0 1 48%;
                    .user-details {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 20px;
                        .initials {
                            width: 70px;
                            height: 70px;
                            border-radius: 50px;
                            background: #eee;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: 600;
                            font-size: 20px;
                            margin-right: 20px;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .name {
                            font-weight: bold;
                            font-size: 18px;
                            small {
                                font-size: 14px;
                                font-weight: normal;
                                display: block;
                            }
                        }
                        .user-review-stars {
                            li {
                                font-size: 20px;
                                color: #ccc;
                                &.filled {
                                    color: #ffc107;
                                }
                            }
                        }
                    }
                    .user-review-text {
                        font-size: 14px;
                        line-height: 1.6;
                        padding: 20px;
                        border: 1px solid #ddd;
                        position: relative;
                        &:before {
                            content: "";
                            position: absolute;
                            border-style: solid;
                            border-width: 0 10px 10px 10px;
                            border-color: transparent transparent #ffffff transparent;
                            top: -9px;
                            left: 25px;
                            z-index: 2;
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            border-style: solid;
                            border-width: 0 10px 10px 10px;
                            border-color: transparent transparent #ddd transparent;
                            top: -11px;
                            left: 25px;
                        }
                    }
                }
            }
            .pagination-holder {
                .pagination {
                    justify-content: flex-end;
                    .page-item {
                        &.active {
                            .page-link {
                                color: #fff;
                                background: var(--primary);
                                border-color: var(--primary);
                            }
                        }
                        .page-link {
                            color: #000;
                            &:hover {
                                color: #000 !important;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1200px) {
            .reviews {
                padding: 50px 40px 40px;
                h2 {
                    font-size: 20px;
                }
                .review-stars {
                    font-size: 16px;
                }
                .filter-by-ratings {
                    display: none;
                }
                .reviews-trust {
                    margin-bottom: 30px;
                }
                .review-lists {
                    .review-list {
                        flex: 0 0 100% !important;
                        .user-details {
                            display: block;
                            .user-review-stars {
                                li {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 992px) {
            .box-card {
                font-size: 14px;
                line-height: 2;
                padding: 20px;
                margin: 0 !important;
                h2 {
                    font-size: 20px;
                }
                .nav-pills {
                    flex-direction: row !important;
                    margin-bottom: 20px;
                    .nav-item {
                        a {
                            border-radius: 20px !important;
                        }
                    }
                }
            }
            .salon-services {
                .services-list {
                    li {
                        width: 100% !important;
                    }
                    label {
                        strong {
                            font-size: 14px !important;
                        }
                        .duration {
                            font-size: 13px !important;
                        }
                        .ser-price {
                            font-size: 14px;
                            top: auto !important;
                            bottom: -1px;
                        }
                        .disc-price {
                            font-size: 14px;
                            top: auto !important;
                            bottom: -1px;
                        }
                    }
                }
                .salon-reviews {
                    .ratings-box {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}
.react-rater {
    margin-right: 10px;
    .react-rater-star {
        color: #ccc !important;
        &.is-active-half {
            color: #ccc !important;
            &:before {
                color: #ffc107 !important;
            }
        }
        &.is-active {
            color: #ffc107 !important;
            &:before {
                color: #ffc107 !important;
            }
        }
    }
}