.search-banner {
    background: #f2f2f7; padding: 30px 0 40px;
    .h-list {grid-gap: 25px;}
    .h-list > .h-list {grid-gap: 5px;}

    @media screen and (max-width: 812px) {
        .h-list {grid-gap: 10px;} 
        .h-list > .h-list {display: none;}
    }
}

.results {
    width: 100%; display: grid; grid-gap: 15px; grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
}

.google-map {
    width: 1300px; margin-left: 25px;
    iframe {height: 100%; width: 100%;}
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255,255,255,0);
    display: none;
    z-index: 2;
    &.active {
        display: block;
    }
}
.search-card {
    box-shadow: 0 2px 10px #ddd;
    .grid {
        padding: 10px;
        position: relative;
        h5 {
            width: 75%;
            margin-bottom: 10px !important;
        }
        h6 {
            width: 75%;
        }
    }
    .image{
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        padding-right: 30px;
        .label{
            flex-grow: 1 ;
            margin: 3px;
            position: relative;
            max-width: fit-content;
            background-color: #ffffff;
        }
    } 
    @media screen and (max-width: 767px){
        margin: 0 10px;
        
    }
}
.search-bar {
    position: absolute;
    top: 0;
    width: auto !important;
    left: 200px;
    box-shadow: none !important;
    transition: none !important;
    .search-services {
        .closeBtn {
            position: absolute;
            top: 6px;
            right: 15px;
            width: 10px;
            height: 10px;
            z-index: 2;
            opacity: 0.5;
            cursor: pointer;
            .svg-icon {
                width: 100%;
                height: 100%;
            }
            &:hover {
                opacity: 1;
            }
        }
    }
    @media screen and (max-width: 767px) {
        position: relative;
        left: 0;
        width: 100%;
        .h-list {
            display: block;
            width: 100%;
            .search-wrapper {
                flex-direction: column;
                .search-services {
                    width: 100%;
                }
                h6 {
                    display: none;
                }
            }
        }
        &.open {
            opacity: 1;
            visibility: visible;
            top: 80px;
            left: 0;
            right: 0;
            transition: 0.3s all ease;
        }
    }
}
.sticky-header .search-bar {
    background: transparent;
    width: auto !important;
    transition: none;
    display: inline-flex;
    @media screen and (max-width: 767px) {
        padding: 10px 20px 20px;
        top: 79px;
        width: 100% !important;
        background-color: #fff;
    }
}

@media screen and (max-width: 767px) {
    .profile-dropdown {
        display: flex;
        align-items: center;
    }
}