.auth-page {
    background-color: #f2f2f7; height: 100%; padding: 20px 20px 50px;

    .block-content {max-width: 456px; margin: auto;}

    h5 {font-size: 20px;}
    h6 {font-weight: 500;}

    .inner-head {display: grid; grid-template-columns: 30px 1fr 30px; align-items: center; margin-bottom: 25px;}
    .inner-head .icon {transform: rotate(180deg);}
    .inner-head img {max-width: 120px; margin: auto;}

    .content-holder {
        padding: 24px 40px; margin: 0px auto 20px; border-radius: 8px; box-shadow: rgba(16, 25, 40, .08) 0px 4px 8px 0px; background: white; overflow: hidden;
        
        .buttons-group {display: grid; grid-gap: 15px; margin: 30px 0 35px;}
        .btn {width: 100%; min-height: 44px; border-radius: 4px;}

        .btn-social {display: grid; padding: 0; grid-template-columns: 50px 1fr 50px; align-items: center; box-shadow: rgba(213, 215, 218, 1) 0px 4px 6px 0px; border: 1px solid rgba(213, 215, 218,1);}
        .btn-social .icon {display: flex; align-items: center; justify-content: center; border-right: 1px solid rgba(213, 215, 218,1); height: 100%;}
        .btn-social svg,
        .btn-social img {width: 24px; margin: auto;}
    }

    .fb-icon svg {width: 14px !important; color: #4285f4;}

    &.signup-form .block-content {max-width: 700px;}

    @media screen and (min-width: 1440px){
        min-height: 100vh;
    }

    @media screen and (max-width: 991px){
        padding: 20px 0;
        .block-content {max-width: 100%;}
        .content-holder {padding: 24px 20px;}
    }

}

.profile-page {
    background: #f2f2f7; padding: 50px 0px 100px;
    .profile-holder {max-width: 700px; margin: auto; padding: 24px 40px 60px; border-radius: 8px; box-shadow: rgba(16, 25, 40, .08) 0px 4px 8px 0px; background: white; overflow: hidden;}
    .profile-holder .image {position: relative; width: 200px; height: 200px; margin: 0 auto 50px; }
    .profile-holder .image img { border-radius: 100%; overflow: hidden;}
    .profile-holder .image .icon {color: #fff; background: var(--link-color); box-shadow: 0px 3px 10px rgba(0,0,0,.16); position: absolute; right: 10px; bottom: 10px; }
}